ion-title {
    text-align: center;
    color: #8b61ff;
    text-transform: uppercase;
    margin-bottom: 20px !important;
}

ion-button {
    width: 300px;
    margin-right: auto !important;
    margin-left: auto !important;
    margin-top: 35px !important;
}

@media only screen and (min-width: 799px){
    ion-list ion-item {
        width: 400px !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}
@media only screen and (max-width: 800px) {
    ion-item {
    --padding-start: 10px !important;
    --padding-end: 20px !important;
    text-align: center !important;
    }
}

ion-img {
    width: 100px;
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

div.error {
    width: 100%;
    text-align: center;
}

.icon-toggle {
    cursor: pointer;
    margin-top: -30px;
    margin-left: 340px;
    z-index: 100;
}