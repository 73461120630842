ion-title {
    color: black !important;
    margin-top: 30px;
    position: sticky !important;
    padding-inline: 0px !important;
}

ion-item.orgSelect, ion-label, ion-ripple-effect {
width: 350px;
margin-left: auto;
margin-right: auto;

}

ion-content {
    padding: 0px !important;
}

ion-grid {
    width: 100%;
    text-align: center;
    margin: 0px;
}

ion-col {
    text-align: center;
}

ion-col.three {
    text-align: center;
}

.blue {
    background-color: #17184c;
    color: #ffffff;
    align-items: center;
    font-size: 36px;
    font-weight: 400;
    padding: 50px !important;
}

.title-item {
    font-weight: 350;
    margin: 5px;
    font-size: 24px;
    padding: auto;
    border-radius: 10px;

}

.move-down {
    margin-top: 30px;
   
}

.info-num {
    color: #8b61ff !important;
    font-size: 46px;
    height: 100% !important;
    margin: auto !important;
}

ion-row.centered {
    width: 100%;
    text-align: center !important;
}

.num-title {
    text-decoration: underline;
    font-style: oblique;
    font-size: 24px;
}

.big-num {
    font-size: 56px;
    font-weight: 200;
    margin-top: -20px;
}

.centered {
    margin-top: 20px;
}

.chart-title {
    font-size: 20px;
    font-weight: 500;
    color: #707070;
}

@media only screen and (max-width: 768px) {
    .three {
    width: 100% !important;
    }
    .info-num {
        font-size: 170% !important;
        margin-top: 5px !important;
        width: 100% !important;
        white-space: wrap !important;
    }
    .title-item {
        width: 100% !important;
        height: auto !important;
        font-size: 150%;
        margin: 10px !important;
        padding: auto !important;
        border: none !important;
        white-space: wrap !important;
    }
    .large-header {
        font-size: 24px !important;
        position: unset !important;
        height: auto !important;
        border-top: 1px solid #17184c;
    }
    .full-pie {
        width: 100% !important;
    }
    .blue {
        padding: 20px;
    }
    .pie-size {
        flex-basis: 100% !important;
        width: 100% !important;
    }
   .pie-inn {
        margin: auto !important;
        height: auto !important;
        width: 100% !important;
        flex-basis: 100% !important;
        text-align: center !important;
        vertical-align: middle !important;
    }
    .underline-me {
        border: 1px solid #8b61ff;
        background-color: #edecf8;
        width: 100% !important;
        padding: auto;
    }
    .underline-me-backwards {
        border: 1px solid #3399ff;
        background-color: #3399ff21;
        padding: auto;
       
        margin: auto;
    }
    .col-change {
        flex-basis: 100% !important;
    }
    .middle-col {
        margin: 40px 0px 40px 0px;
        background: #7cb6e15e;
        padding: 30px 0px 30px 0px;
    }
}

.large-header {
    font-size: 32px;
    position: unset !important;
}

.course-titles {
    width: 75% !important;
}

.pie-size {
   width: 40% !important;
}

.pie-head {
    font-size: 26px !important;
    font-weight: 600 !important;
    color: #707070 !important;
}
.underline-me-backwards {
    font-size: 20px !important;
    padding: 5px !important;
    margin: auto !important;
    width: 100% !important;
    color: #707070 !important;
}

.underline-me {
    font-size: 20px !important;
    padding: auto !important;
    color: #707070 !important;
}

.cusion-row {
    padding-top: 30px;
}

.pie-title {
    font-size: 26px !important;
    color: #707070 !important;
    font-weight: 400 !important;
}

.flex-item {
    display: flex;
}

ion-button {
    right: 10px !important;
    position: relative !important;
}