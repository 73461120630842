.twentyone {
    color: #31a5a4;
}

.twentytwo {
    color: #3399ff;
}

.intro{
    font-weight: bold;
    text-transform: uppercase;
}

.custom-tooltip {
    padding: 0px 10px;
}
